
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      eventList: {
        type: Object,
        required: true,
      },
    },
    methods: {
      dealWith(product) {
        this.$store.commit('productInfoModule/currentProductInfo', {
          productId: product.productId, pvId: product.pvId, status: product.type ? 10 : 20, statusTxt: product.type ? '审核中' : '未通过', name: product.productName,
        });
        this.$router.push({
          path: '/product/edit',
        });
      },
    },
  });
