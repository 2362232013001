<template>
  <div class="entrance">
    <h2>
      <span>快捷入口</span>
      <span @click="$router.push({ path: '/home/entrance' })">设置</span>
    </h2>
    <ul>
      <li v-for="({name, code}, index) in selectedEntrance" :key="index" @click="$router.push({path: entrance[code].path})">
        <img :src="require(`../../assets/images/systemindex/${entrance[code].icon}`)" alt="icon">
        <p>{{name}}</p>
      </li>
      <li @click="$router.push({ path: '/home/entrance' })">
        <img :src="require(`../../assets/images/systemindex/btn_add.png`)" alt="icon">
        <p>添加入口</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { entrance } from './entrance.js';

export default {
  data() {
    return {
      entrance,
    };
  },
  computed: {
    selectedEntrance() {
      const selectedEntrance = [];
      this.$store.state.entranceList.forEach((element) => {
        element.children.forEach((item) => {
          if (item.selected) {
            selectedEntrance.push(item);
          }
        });
      });
      return selectedEntrance;
    },
  },
};
</script>

<style lang="less" scoped>
  .entrance{
    padding: 16px;
    color:#333;
    h2{
      font-size: 16px;
      padding-bottom: 13px;
      display: flex;
      font-weight: 500;
      justify-content: space-between;
      border-bottom: 1px solid #eeeeee;
      >span:last-child{
        cursor: pointer;
        font-size: 12px;
      }
    }
    ul{
      .flex(space-between);
      flex-wrap: wrap;
      li{
          padding: 10px 0;
          width: 100px;
          text-align: center;
          cursor: pointer;
          margin: 0 10px;
          margin-top: 22px;
        p{
          margin-top: 8px;
        }
      }
    }
    .ul:after{
      display: block;
      width: 100px;
      height: 0;
    }
  }
</style>
