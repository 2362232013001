
  import { TodoEvents } from '@/api/types';
  import Vue from 'vue';
  import * as api from '@/api';
  import Card from './Card.vue';
  import Entrance from './Entrance.vue';
  import EventPanel from './EventPanel.vue';

  export default Vue.extend({
    components: {
      Card,
      EventPanel,
      Entrance,
    },
    computed: {
      entranceList() {
        return this.$store.state.entranceList;
      },
      memberMenuPermission() {
        const df = this.$store.state.entranceList.find((item) => item.name === '用户板块');
        return df ? df.children.findIndex((item) => item.name === '会员列表') !== -1 : false;
      },
    },
    data() {
      return {
        cardData: [
          {
            h2: '产品总览',
            data: {},
          },
          {
            h2: '会员总览',
            data: {},
          },
        ] as any,
        events: {} as TodoEvents,
      };
    },
    methods: {
      async fetchUser() {
        const userRes = await api.getUser();
        if (api.error(userRes)) {
          return false;
        }
        this.$store.commit('setUser', userRes.data);
        const permissionRes = await api.getUserPermissionTree();
        if (api.error(permissionRes)) {
          return false;
        }
        this.$store.commit('setPermission', permissionRes.data.roots);
        return true;
      },
      async getProductSummary() {
        const res = await api.getProductSummary();
        if (!api.error(res)) {
          (this as any).cardData[0].data = res.data;
        }
      },
      async getUserSummary() {
        const res = await api.getUserSummary();
        if (!api.error(res)) {
          (this as any).cardData[1].data = res.data;
        }
      },
      async getTodoEvent() {
        const res = await api.getTodoEvent();
        if (!api.error(res)) {
          (this as any).events = res.data;
          (this as any).$store.commit('hasEvent', res.data.items.length);
        }
      },
    },
    created() {
      (this as any).fetchUser();
      this.$store.dispatch('getOptionalTree');
      this.$store.commit('breadcrumbList', [
        {
          name: '系统首页',
          path: '/',
        },
      ]);
    },
    mounted() {
      (this as any).getProductSummary();
      (this as any).getUserSummary();
      (this as any).getTodoEvent();
    },
  });
