<template>
  <div class="card">
    <h2>
      {{cardData.h2}}
    </h2>
    <div>
      <ul>
        <li
          v-for="(value, key, index) in cardData.data"
          :key="index">
          <p>{{value}}</p>
          <p>{{keyFilters[key]}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['cardData'],
  data() {
    return {
      keyFilters: {
        onlineCount: '已上架',
        offlineCount: '已下架',
        allCount: '全部产品',
        todayIncreaseCount: '今日新增',
        yesterdayIncreaseCount: '昨日新增',
        monthIncreaseCount: '本月新增',
        userTotalCount: '会员总数',
      },
    };
  },
};
</script>

<style lang="less" scoped>
  .card{
    color: #ffffff;
    padding: 16px;
    border-radius: 4px;
    h2{
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 14px;
      border-bottom: 1px solid rgba(255,255,255,0.3);
    }
    div{
      padding: 0 10px;
      ul{
        margin-left: 170px;
        display: flex;
        justify-content: space-between;
        li{
          height: 70px;
          margin: 46px 0 30px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;
          p:first-child{
            font-size: 30px;
            font-family: 'YouSheBiaoTiHei';
          }
          p:last-child{
            font-size: 14px;
          }
        }
      }
    }
  }
</style>
