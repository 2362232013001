export const entrance = {
  MessageSettingMenu: { // 消息设置
    icon: 'btn_xiaoxishezhi.png',
    path: '/operation/messageSet',
  },
  HelpMenu: { // 帮助列表
    icon: 'btn_bangzhu_liebiao.png',
    path: '/content', // TODO: 由于内容模块还没有做，这个path暂时这样，之后改掉
  },
  HelpCategoryMenu: { // 帮助分类
    icon: 'btn_bangzhu_fenlei.png',
    path: '/content', // TODO: 由于内容模块还没有做，这个path暂时这样，之后改掉
  },
  TrafficStatisticsMenu: { // 统计流量
    icon: 'btn_tongji_liuliang.png',
    path: '/statistics/globalStatistics',
  },
  ProductStatisticsMenu: { // 统计产品
    icon: 'btn_tongji_chanping.png',
    path: '/statistics/productStatistics',
  },
  ProductTypeMenu: { // 产品分类
    icon: 'btn_chanping_fenlei.png',
    path: '/product/classification',
  },
  ProductRecycleBinMenu: { // 产品回收站
    icon: 'btn_chanping_huishouzhan.png',
    path: '/product/recycleBin',
  },
  ProductAuditMenu: { // 产品审核
    icon: 'btn_chanpin_shenhe.png',
    path: '/product/audit',
  },
  ProductMenu: { // 产品列表
    icon: 'btn_chanpin_liebiao.png',
    path: '/product/list',
  },
  ProductCategoryMenu: { // 类型管理
    icon: 'btn_chanping_leixing.png',
    path: '/product/type',
  },
  BrandMenu: { // 品牌管理
    icon: 'btn_chanping_pingpai.png',
    path: '/product/brand',
  },
  LevelPermissionMenu: { // 等级权限
    icon: 'btn_huiyuan_dengji.png',
    path: '/user/level',
  },
  TaskSettingMenu: { // 任务设置
    icon: 'btn_huiyuan_renwu.png',
    path: '/user/task',
  },
  UserRoleMenu: { // 这里'UserRoleMenu'后台取名有问题吧。中文名称是用户列表
    icon: 'btn_yonghu_juese.png',
    path: '/user/list',
  },
  UserMenu: {
    icon: 'btn_yonghu_liebiao.png',
    path: '/user/role',
  },
  MemberMenu: { // 会员列表
    icon: 'btn_huiyuan_liebiao.png',
    path: '/user/memberList',
  },
};
